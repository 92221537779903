// src/components/ThreePointerHub.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ThreePointerHub.css';
import PlayerList from './PlayerList.js';

// Mapping from team abbreviation to their logo URLs
const teamAbbreviationToLogoUrl = {
  BOS: 'https://cdn.nba.com/logos/nba/1610612738/primary/L/logo.svg',
  BKN: 'https://cdn.nba.com/logos/nba/1610612751/primary/L/logo.svg',
  NYK: 'https://cdn.nba.com/logos/nba/1610612752/primary/L/logo.svg',
  PHI: 'https://cdn.nba.com/logos/nba/1610612755/primary/L/logo.svg',
  TOR: 'https://cdn.nba.com/logos/nba/1610612761/primary/L/logo.svg',
  CHI: 'https://cdn.nba.com/logos/nba/1610612741/primary/L/logo.svg',
  CLE: 'https://cdn.nba.com/logos/nba/1610612739/primary/L/logo.svg',
  DET: 'https://cdn.nba.com/logos/nba/1610612765/primary/L/logo.svg',
  IND: 'https://cdn.nba.com/logos/nba/1610612754/primary/L/logo.svg',
  MIL: 'https://cdn.nba.com/logos/nba/1610612749/primary/L/logo.svg',
  ATL: 'https://cdn.nba.com/logos/nba/1610612737/primary/L/logo.svg',
  CHA: 'https://cdn.nba.com/logos/nba/1610612766/primary/L/logo.svg',
  MIA: 'https://cdn.nba.com/logos/nba/1610612748/primary/L/logo.svg',
  ORL: 'https://cdn.nba.com/logos/nba/1610612753/primary/L/logo.svg',
  WAS: 'https://cdn.nba.com/logos/nba/1610612764/primary/L/logo.svg',
  DEN: 'https://cdn.nba.com/logos/nba/1610612743/primary/L/logo.svg',
  MIN: 'https://cdn.nba.com/logos/nba/1610612750/primary/L/logo.svg',
  OKC: 'https://cdn.nba.com/logos/nba/1610612760/primary/L/logo.svg',
  POR: 'https://cdn.nba.com/logos/nba/1610612757/primary/L/logo.svg',
  UTA: 'https://cdn.nba.com/logos/nba/1610612762/primary/L/logo.svg',
  GSW: 'https://cdn.nba.com/logos/nba/1610612744/primary/L/logo.svg',
  LAC: 'https://cdn.nba.com/logos/nba/1610612746/primary/L/logo.svg',
  LAL: 'https://cdn.nba.com/logos/nba/1610612747/primary/L/logo.svg',
  PHX: 'https://cdn.nba.com/logos/nba/1610612756/primary/L/logo.svg',
  SAC: 'https://cdn.nba.com/logos/nba/1610612758/primary/L/logo.svg',
  DAL: 'https://cdn.nba.com/logos/nba/1610612742/primary/L/logo.svg',
  HOU: 'https://cdn.nba.com/logos/nba/1610612745/primary/L/logo.svg',
  MEM: 'https://cdn.nba.com/logos/nba/1610612763/primary/L/logo.svg',
  NOP: 'https://cdn.nba.com/logos/nba/1610612740/primary/L/logo.svg',
  SAS: 'https://cdn.nba.com/logos/nba/1610612759/primary/L/logo.svg',
};

// Array of NBA teams with their abbreviations, names, and colors
const nbaTeams = [
  { abbreviation: 'ATL', name: 'Atlanta Hawks', color: '#E03A3E' },
  { abbreviation: 'BOS', name: 'Boston Celtics', color: '#007A33' },
  { abbreviation: 'BKN', name: 'Brooklyn Nets', color: '#000000' },
  { abbreviation: 'CHA', name: 'Charlotte Hornets', color: '#1D1160' },
  { abbreviation: 'CHI', name: 'Chicago Bulls', color: '#CE1141' },
  { abbreviation: 'CLE', name: 'Cleveland Cavaliers', color: '#6F263D' },
  { abbreviation: 'DAL', name: 'Dallas Mavericks', color: '#00538C' },
  { abbreviation: 'DEN', name: 'Denver Nuggets', color: '#0E2240' },
  { abbreviation: 'DET', name: 'Detroit Pistons', color: '#C8102E' },
  { abbreviation: 'GSW', name: 'Golden State Warriors', color: '#1D428A' },
  { abbreviation: 'HOU', name: 'Houston Rockets', color: '#CE1141' },
  { abbreviation: 'IND', name: 'Indiana Pacers', color: '#002D62' },
  { abbreviation: 'LAC', name: 'LA Clippers', color: '#C8102E' },
  { abbreviation: 'LAL', name: 'Los Angeles Lakers', color: '#552583' },
  { abbreviation: 'MEM', name: 'Memphis Grizzlies', color: '#5D76A9' },
  { abbreviation: 'MIA', name: 'Miami Heat', color: '#98002E' },
  { abbreviation: 'MIL', name: 'Milwaukee Bucks', color: '#00471B' },
  { abbreviation: 'MIN', name: 'Minnesota Timberwolves', color: '#0C2340' },
  { abbreviation: 'NOP', name: 'New Orleans Pelicans', color: '#0C2340' },
  { abbreviation: 'NYK', name: 'New York Knicks', color: '#006BB6' },
  { abbreviation: 'OKC', name: 'Oklahoma City Thunder', color: '#007AC1' },
  { abbreviation: 'ORL', name: 'Orlando Magic', color: '#0077C0' },
  { abbreviation: 'PHI', name: 'Philadelphia 76ers', color: '#006BB6' },
  { abbreviation: 'PHX', name: 'Phoenix Suns', color: '#1D1160' },
  { abbreviation: 'POR', name: 'Portland Trail Blazers', color: '#E03A3E' },
  { abbreviation: 'SAC', name: 'Sacramento Kings', color: '#5A2D81' },
  { abbreviation: 'SAS', name: 'San Antonio Spurs', color: '#C4CED4' },
  { abbreviation: 'TOR', name: 'Toronto Raptors', color: '#CE1141' },
  { abbreviation: 'UTA', name: 'Utah Jazz', color: '#002B5C' },
  { abbreviation: 'WAS', name: 'Washington Wizards', color: '#002B5C' },
];

// Mapping from team abbreviation to their colors
const teamAbbreviationToColor = nbaTeams.reduce((acc, team) => {
  acc[team.abbreviation] = team.color;
  return acc;
}, {});

// Mapping from ESPN team abbreviations to our abbreviations
const espnToOurAbbreviationMap = {
  BKN: 'BKN',
  BOS: 'BOS',
  NY: 'NYK',
  NYK: 'NYK',
  PHI: 'PHI',
  TOR: 'TOR',
  CHI: 'CHI',
  CLE: 'CLE',
  DET: 'DET',
  IND: 'IND',
  MIL: 'MIL',
  ATL: 'ATL',
  CHA: 'CHA',
  MIA: 'MIA',
  ORL: 'ORL',
  WSH: 'WAS',
  DEN: 'DEN',
  MIN: 'MIN',
  OKC: 'OKC',
  POR: 'POR',
  UTAH: 'UTA',
  GS: 'GSW',
  GSW: 'GSW',
  LAC: 'LAC',
  LAL: 'LAL',
  PHX: 'PHX',
  SAC: 'SAC',
  DAL: 'DAL',
  HOU: 'HOU',
  MEM: 'MEM',
  NO: 'NOP',
  NOP: 'NOP',
  SA: 'SAS',
  SAS: 'SAS',
};

// Helper function to display the rank with appropriate color
const displayRank = (teamAbbreviation, teamThreePMRanks) => {
  const teamData = teamThreePMRanks[teamAbbreviation];
  if (!teamData) return <span className="rank-text">N/A</span>;

  const { rank, avgThreePM } = teamData;
  let rankColor = 'red';

  if (rank !== 'N/A') {
    // Extract numerical rank from rankDisplayValue (e.g., "3rd" -> 3)
    const numericRankMatch = rank.match(/(\d+)/);
    const numericRank = numericRankMatch ? parseInt(numericRankMatch[1], 10) : null;

    if (numericRank !== null) {
      if (numericRank >= 1 && numericRank <= 10) rankColor = 'green';
      else if (numericRank > 10 && numericRank <= 20) rankColor = 'orange';
      else rankColor = 'red';
    } else {
      console.warn(`Unable to parse numerical rank from: ${rank}`);
    }
  }

  return (
    <span className="rank-text" style={{ color: rankColor }}>
      {rank !== 'N/A' ? `${rank} 3PM` : 'N/A'}
    </span>
  );
};

const ThreePointerHub = () => {
  const [games, setGames] = useState([]);
  const [teamThreePMRanks, setTeamThreePMRanks] = useState({});
  const [expandedGames, setExpandedGames] = useState({});

  useEffect(() => {
    // Fetch today's games from ESPN API
    const fetchGames = async () => {
      try {
        const today = new Date();
        const estDate = new Date(
          today.toLocaleString('en-US', { timeZone: 'America/New_York' })
        );
        const yyyy = estDate.getFullYear();
        const mm = String(estDate.getMonth() + 1).padStart(2, '0');
        const dd = String(estDate.getDate()).padStart(2, '0');
        const dateStr = `${yyyy}${mm}${dd}`;
        const espnUrl = `https://site.api.espn.com/apis/site/v2/sports/basketball/nba/scoreboard?dates=${dateStr}`;
        const response = await axios.get(espnUrl);

        const teamRanksTemp = {};

        const gamesData = response.data.events.map((event) => {
          const competition = event.competitions[0];
          if (!competition) {
            console.warn(`No competition data for event ID: ${event.id}`);
            return null;
          }

          const competitors = competition.competitors;
          if (!competitors) {
            console.warn(
              `No competitors data for competition in event ID: ${event.id}`
            );
            return null;
          }

          const homeTeam = competitors.find(
            (team) => team.homeAway === 'home'
          );
          const awayTeam = competitors.find(
            (team) => team.homeAway === 'away'
          );

          if (!homeTeam || !awayTeam) {
            console.warn(
              `Missing home or away team in event ID: ${event.id}`
            );
            return null;
          }

          // Extract team statistics from each competitor's stats
          const extractThreePM = (team) => {
            if (team.statistics && Array.isArray(team.statistics)) {
              const threePMStat = team.statistics.find(
                (stat) => stat.name === 'threePointFieldGoalsMade'
              );
              if (threePMStat) {
                return {
                  rank: threePMStat.rankDisplayValue || 'N/A',
                  avgThreePM: threePMStat.displayValue || 'N/A',
                };
              } else {
                console.warn(
                  `No 'threePointFieldGoalsMade' stat found for team: ${team.team.displayName} in event ID: ${event.id}`
                );
                return {
                  rank: 'N/A',
                  avgThreePM: 'N/A',
                };
              }
            } else {
              console.warn(
                `No statistics available for team: ${team.team.displayName} in event ID: ${event.id}`
              );
              return {
                rank: 'N/A',
                avgThreePM: 'N/A',
              };
            }
          };

          const homeStats = extractThreePM(homeTeam);
          const awayStats = extractThreePM(awayTeam);

          const homeAbbr =
            espnToOurAbbreviationMap[homeTeam.team.abbreviation] ||
            homeTeam.team.abbreviation;
          const awayAbbr =
            espnToOurAbbreviationMap[awayTeam.team.abbreviation] ||
            awayTeam.team.abbreviation;

          // Populate teamRanksTemp with extracted stats
          teamRanksTemp[homeAbbr] = homeStats;
          teamRanksTemp[awayAbbr] = awayStats;

          return {
            id: event.id,
            date: event.date,
            homeTeam: {
              name: homeTeam.team.displayName,
              abbreviation: homeAbbr,
              logo: teamAbbreviationToLogoUrl[homeAbbr],
            },
            awayTeam: {
              name: awayTeam.team.displayName,
              abbreviation: awayAbbr,
              logo: teamAbbreviationToLogoUrl[awayAbbr],
            },
          };
        });

        // Filter out any null entries due to missing data
        const filteredGamesData = gamesData.filter((game) => game !== null);

        setGames(filteredGamesData);
        setTeamThreePMRanks(teamRanksTemp);
      } catch (error) {
        console.error('Error fetching games:', error);
      }
    };

    fetchGames();
  }, []);

  const toggleExpand = (gameId) => {
    setExpandedGames((prevState) => ({
      ...prevState,
      [gameId]: !prevState[gameId],
    }));
  };

  return (
    <div className="tp-hub-three-pointer-hub">
      {games.map((game) => (
        <div key={game.id} className="tp-hub-game-container">
          <div
            className="tp-hub-game-header"
            onClick={() => toggleExpand(game.id)}
          >
            <div className="tp-hub-team-info">
              <img
                src={game.awayTeam.logo}
                alt={game.awayTeam.name}
                className="tp-hub-team-logo"
              />
              <span className="tp-hub-team-name">{game.awayTeam.name}</span>
              <div className="tp-hub-team-rank">
                {displayRank(game.awayTeam.abbreviation, teamThreePMRanks)}
              </div>
            </div>
            <div className="tp-hub-vs">vs</div>
            <div className="tp-hub-team-info">
              <img
                src={game.homeTeam.logo}
                alt={game.homeTeam.name}
                className="tp-hub-team-logo"
              />
              <span className="tp-hub-team-name">{game.homeTeam.name}</span>
              <div className="tp-hub-team-rank">
                {displayRank(game.homeTeam.abbreviation, teamThreePMRanks)}
              </div>
            </div>
          </div>
          {expandedGames[game.id] && (
            <div className="tp-hub-game-details">
              {/* Away Team Section */}
              <div
                className="tp-hub-team-section"
                style={{
                  backgroundColor:
                    teamAbbreviationToColor[game.awayTeam.abbreviation],
                }}
              >
                <h3 className="tp-hub-team-section-title">
                  {game.awayTeam.name}
                </h3>
                <PlayerList teamAbbreviation={game.awayTeam.abbreviation} />
              </div>
              {/* Home Team Section */}
              <div
                className="tp-hub-team-section"
                style={{
                  backgroundColor:
                    teamAbbreviationToColor[game.homeTeam.abbreviation],
                }}
              >
                <h3 className="tp-hub-team-section-title">
                  {game.homeTeam.name}
                </h3>
                <PlayerList teamAbbreviation={game.homeTeam.abbreviation} />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

// Export the main component
export default ThreePointerHub;
