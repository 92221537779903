// src/components/Navbar.js

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  FaBaseballBall,
  FaBasketballBall,
  FaFootballBall,
  FaHockeyPuck,
  FaTools,
} from 'react-icons/fa';
import './Navbar.css'; // Import Navbar-specific CSS

function Navbar({
  darkMode,
  toggleDarkMode,
  isAuthenticated,
  setIsAuthenticated,
  handleComingSoonClick,
}) {
  const navigate = useNavigate();
  const [menuActive, setMenuActive] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleTitleClick = () => {
    if (window.innerWidth <= 768) {
      navigate('/');
      setMenuActive(false);
      closeAllSubmenus();
    }
  };

  const toggleMenu = () => {
    setMenuActive(!menuActive);
    closeAllSubmenus();
  };

  const closeAllSubmenus = () => {
    setOpenDropdown(null);
  };

  const handleLogout = () => {
    window.location.href = 'https://zeusanalytics.org/accounts/logout';
  };

  const handleLogin = () => {
    window.location.href = 'https://zeusanalytics.org/accounts/dubclub/login/';
  };

  const handleDropdownClick = (dropdownName) => {
    if (openDropdown === dropdownName) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(dropdownName);
    }
  };

  return (
    <nav className="navbar-container">
      <div className="navbar-left">
        <Link to="/" className="navbar-item" onClick={handleTitleClick}>
          <img
            src="/zeusanalyticslogo2_BG_resized.png"
            alt="Home"
            className="navbar-home-icon"
          />
        </Link>
        <span className="navbar-z-mobile" onClick={handleTitleClick}>
          Zeus Analytics
        </span>
        <span className="navbar-title">Zeus Analytics</span>
      </div>
      <div className="navbar-hamburger" onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className={`navbar-right ${menuActive ? 'active' : ''}`}>
        {/* BETA Label */}
        <div className="navbar-beta-label">BETA</div>

        {/* Dark Mode Toggle */}
        <div className="navbar-dark-mode-toggle">
          <label className="navbar-switch">
            <input
              type="checkbox"
              checked={darkMode}
              onChange={toggleDarkMode}
            />
            <span className="navbar-slider round"></span>
          </label>
          <span className="navbar-dark-mode-text">Dark Mode</span>
        </div>

        {/* MLB Dropdown */}
        <div className="navbar-dropdown">
          <button
            className="navbar-dropbtn"
            onClick={() => handleDropdownClick('MLB')}
          >
            <FaBaseballBall className="navbar-icon" /> MLB
          </button>
          <div
            className={`navbar-dropdown-content ${
              openDropdown === 'MLB' ? 'show' : ''
            }`}
          >
            <Link to="/mlb/the-script" onClick={closeAllSubmenus}>
              The Script
            </Link>
            <Link to="/mlb/exit-velocity-tracker" onClick={closeAllSubmenus}>
              Exit Velocity Tracker
            </Link>
            <Link to="/mlb/whos-hot" onClick={closeAllSubmenus}>
              Who's Hot
            </Link>
            <Link to="/mlb/starting-pitcher-stats" onClick={closeAllSubmenus}>
              Starting Pitcher Stats
            </Link>
            <Link to="/mlb/daily-lineup-stats" onClick={closeAllSubmenus}>
              Daily Lineup Stats
            </Link>
            <Link to="/mlb/inning-first-stats" onClick={closeAllSubmenus}>
              1st Inning Stats
            </Link>
            <Link to="/mlb/park-factors" onClick={closeAllSubmenus}>
              Park Factors
            </Link>
            <Link to="/mlb/scores" onClick={closeAllSubmenus}>
              Scores
            </Link>
            <Link to="/mlb/definition" onClick={closeAllSubmenus}>
              Definitions
            </Link>
          </div>
        </div>

        {/* NBA Dropdown */}
        <div className="navbar-dropdown">
          <button
            className="navbar-dropbtn"
            onClick={() => handleDropdownClick('NBA')}
          >
            <FaBasketballBall className="navbar-icon" /> NBA
          </button>
          <div
            className={`navbar-dropdown-content ${
              openDropdown === 'NBA' ? 'show' : ''
            }`}
          >
            <Link to="/nba/stat-tracker" onClick={closeAllSubmenus}>
              NBA Stat Tracker
            </Link>
            <Link to="/nba/whos-hot" onClick={closeAllSubmenus}>
              NBA Who's Hot
            </Link>
            <Link to="/nba/awards" onClick={closeAllSubmenus}>
              NBA Daily Awards
            </Link>
            {/* <Link to="/nba/three-pointer-hub" onClick={closeAllSubmenus}>
              3-Pointer Hub
            </Link> */}
            {/* Add more NBA-related links here if needed */}
          </div>
        </div>

        {/* NFL Dropdown */}
        <div className="navbar-dropdown">
          <button
            className="navbar-dropbtn"
            onClick={() => handleDropdownClick('NFL')}
          >
            <FaFootballBall className="navbar-icon" /> NFL
          </button>
          <div
            className={`navbar-dropdown-content ${
              openDropdown === 'NFL' ? 'show' : ''
            }`}
          >
            <Link to="/nfl/top-td-predictions" onClick={closeAllSubmenus}>
              Top TD Predictions
            </Link>
            <Link to="/nfl/top-td-longshots" onClick={closeAllSubmenus}>
              Top TD Longshots
            </Link>
            <Link to="/nfl/defensive-team-stats" onClick={closeAllSubmenus}>
              Defensive Team Stats
            </Link>
            <Link
              to="/nfl/target-shares-and-defensive-coverages"
              onClick={closeAllSubmenus}
            >
              Target Share & Defensive Coverages
            </Link>
            <Link to="/nfl/nfl-hit-rates" onClick={closeAllSubmenus}>
              NFL Hit Rates
            </Link>
          </div>
        </div>

        {/* NHL Dropdown */}
        <div className="navbar-dropdown">
          <button
            className="navbar-dropbtn"
            onClick={() => handleDropdownClick('NHL')}
          >
            <FaHockeyPuck className="navbar-icon" /> NHL
          </button>
          <div
            className={`navbar-dropdown-content ${
              openDropdown === 'NHL' ? 'show' : ''
            }`}
          >
            <Link to="#" onClick={handleComingSoonClick}>
              COMING SOON...
            </Link>
          </div>
        </div>

        {/* Tools Dropdown */}
        <div className="navbar-dropdown">
          <button
            className="navbar-dropbtn"
            onClick={() => handleDropdownClick('Tools')}
          >
            <FaTools className="navbar-icon" /> Tools
          </button>
          <div
            className={`navbar-dropdown-content ${
              openDropdown === 'Tools' ? 'show' : ''
            }`}
          >
            <Link to="/sportsbooks/arbitrage-calculator" onClick={closeAllSubmenus}>
              Arbitrage Calculator
            </Link>
            <Link to="/sportsbooks/odds-converter" onClick={closeAllSubmenus}>
              Odds Converter
            </Link>
            <Link to="/faq" onClick={closeAllSubmenus}>
              FAQ
            </Link>
          </div>
        </div>

        {/* Authentication Links */}
        {!isAuthenticated && (
          <div className="navbar-dropdown">
            <button className="navbar-dropbtn navbar-login-button" onClick={handleLogin}>
              Login
            </button>
          </div>
        )}
        {isAuthenticated && (
          <div className="navbar-dropdown">
            <button className="navbar-dropbtn navbar-login-button" onClick={handleLogout}>
              Logout
            </button>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
