// src/components/PlayerList.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ThreePointerHub.css';

// Sportsbook Name to Logo URL Mapping
const sportsbookNameToLogo = {
  BetRivers:
    'https://gamblespot-images.s3-accelerate.amazonaws.com/images/da4f2a57-b848-475a-b4f8-ae101faca807_30-08-2024-22-03-11.webp',
  Caesars:
    'https://img.sportsbookreview.com/images/sportsbooks/logos/caesars-logo.png',
  FanDuel:
    'https://pbs.twimg.com/profile_images/1562799602008576000/nDltoq9L_400x400.jpg',
  'BetOnline.ag':
    'https://pbs.twimg.com/profile_images/1527009029389697025/O0mS1E9L_400x400.jpg',
  BetMGM:
    'https://www.sportsvideo.org/wp-content/uploads/2020/03/BetMGM-Logo-%E2%80%93-HiRes.png',
  DraftKings:
    'https://play-lh.googleusercontent.com/9SmQILg4QMVVM1yzSfUvO1KVRkTJc6ckynDuGqDg2oEnjcqcW1ERsQlfNbczBXUy8OZt',
  Bovada:
    'https://sportsbetting.legal/wp-content/uploads/2021/06/Bovada-mobile2.png',
};

// Helper function to normalize names
const normalizeName = (name) => {
  // Remove common suffixes like Jr., Sr., III, etc.
  const suffixes = ['jr', 'sr', 'iii', 'iv', 'v'];
  let normalized = name
    .normalize('NFD') // Normalize to decompose combined letters
    .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
    .replace(/[^a-zA-Z\s]/g, '') // Remove non-alphabetic characters
    .trim()
    .toLowerCase();

  // Remove suffixes
  suffixes.forEach((suffix) => {
    const regex = new RegExp(`\\b${suffix}\\b`, 'i');
    normalized = normalized.replace(regex, '');
  });

  // Remove extra spaces after removing suffixes
  normalized = normalized.replace(/\s{2,}/g, ' ').trim();

  return normalized;
};

// Helper function to convert American odds to EV (for determining closest to EV)
const convertOddsToEV = (odds) => {
  const numericOdds = parseInt(odds, 10);
  if (numericOdds > 0) {
    return 100 / numericOdds;
  } else {
    return Math.abs(numericOdds) / 100;
  }
};

// Helper function to format game stats
const formatGameStats = (threePM, threePA) => `${threePM}-${threePA}`;

// Updated PlayerList Component with unique class names
const PlayerList = ({ teamAbbreviation }) => {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(false);

  // Function to determine if current odds are better than existing odds
  const isBetterOdds = (currentOdds, existingOdds) => {
    const curr = parseInt(currentOdds, 10);
    const exist = parseInt(existingOdds, 10);

    if (isNaN(curr) || isNaN(exist)) {
      return false; // Cannot compare, keep existing
    }

    // Positive odds: higher is better
    if (curr > 0 && exist > 0) {
      return curr > exist;
    }
    // Negative odds: closer to zero is better
    if (curr < 0 && exist < 0) {
      return curr > exist; // e.g., -105 is better than -110
    }
    // Positive odds are better than negative odds
    if (curr > 0 && exist < 0) {
      return true;
    }
    if (curr < 0 && exist > 0) {
      return false;
    }
    return false;
  };

  useEffect(() => {
    const fetchPlayerOdds = async () => {
      setLoading(true);
      try {
        // Fetch player prop odds
        const oddsResponse = await axios.get('/api/nba-player-prop-odds/', {
          params: {
            market: 'player_threes',
            outcome: 'Over',
            sort: 'name',
            direction: 'asc',
          },
        });
        const playerOdds = oddsResponse.data.results;

        // Fetch player stats
        const statsResponse = await axios.get('/api/nba-current-team-player-stats/', {
          params: {
            team: teamAbbreviation,
            sort: 'player_name',
            direction: 'asc',
          },
        });
        const playerStats = statsResponse.data.results;

        const teamPlayerNames = playerStats.map((stat) => stat.player_name);

        // Normalize team player names for matching
        const normalizedTeamPlayerNames = teamPlayerNames.map((name) => normalizeName(name));

        // Normalize odds player names
        const normalizedOddsPlayerNames = playerOdds.map((odds) => normalizeName(odds.name));

        // Group player odds by player
        const oddsByPlayer = {};
        playerOdds.forEach((odds) => {
          const normalizedName = normalizeName(odds.name);
          if (normalizedTeamPlayerNames.includes(normalizedName)) {
            if (!oddsByPlayer[normalizedName]) {
              oddsByPlayer[normalizedName] = [];
            }
            oddsByPlayer[normalizedName].push(odds);
          }
        });

        // Fetch player pictures
        const playerPictures = {};
        const batchSize = 10;
        for (let i = 0; i < teamPlayerNames.length; i += batchSize) {
          const batchNames = teamPlayerNames.slice(i, i + batchSize);
          const pictureResponse = await axios.get('/api/nba-player-pictures/', {
            params: {
              player_name: batchNames,
            },
            paramsSerializer: (params) => {
              return batchNames.map((name) => `player_name=${encodeURIComponent(name)}`).join('&');
            },
          });
          pictureResponse.data.results.forEach((p) => {
            const normalizedName = normalizeName(p.player_name);
            playerPictures[normalizedName] = p.profile_picture;
          });
        }

        // Fetch default profile picture URL
        let defaultPictureUrl = 'https://via.placeholder.com/40'; // Fallback URL
        try {
          const defaultPictureResponse = await axios.get('/api/nba-player-pictures/', {
            params: {
              player_name: 'DEFAULT',
            },
          });
          if (defaultPictureResponse.data.results.length > 0) {
            defaultPictureUrl = defaultPictureResponse.data.results[0].profile_picture;
          }
        } catch (error) {
          console.error('Error fetching default profile picture:', error);
        }

        // Function to remove suffixes
        const removeSuffixes = (name) => {
          const suffixes = ['jr', 'sr', 'iii', 'iv', 'v'];
          let result = name;
          suffixes.forEach((suffix) => {
            const regex = new RegExp(`\\b${suffix}\\b`, 'i');
            result = result.replace(regex, '');
          });
          return result.trim();
        };

        // Prepare player data
        const playerData = Object.keys(oddsByPlayer)
          .map((normalizedName) => {
            const playerStat = playerStats.find(
              (stat) => normalizeName(stat.player_name) === normalizedName
            );

            if (!playerStat) {
              console.warn(`No playerStat found for normalizedName: ${normalizedName}`);
              return null;
            }

            const originalName = playerStat.player_name;

            // Find profile picture
            let profilePictureUrl = playerPictures[normalizeName(originalName)];
            if (!profilePictureUrl) {
              profilePictureUrl =
                playerPictures[normalizeName(originalName.replace(/\./g, ''))] ||
                defaultPictureUrl;
              if (!profilePictureUrl) {
                profilePictureUrl =
                  playerPictures[normalizeName(removeSuffixes(originalName))] ||
                  defaultPictureUrl;
              }
            }

            const propsObj = oddsByPlayer[normalizedName]
              .filter((odds) => odds.outcome === 'Over')
              .reduce((acc, curr) => {
                const point = curr.point;
                if (!acc[point]) {
                  acc[point] = curr;
                } else {
                  const existingOdds = acc[point];
                  if (isBetterOdds(curr.odds, existingOdds.odds)) {
                    acc[point] = curr;
                  }
                }
                return acc;
              }, {});

            // Convert propsObj to an array and sort by 'point'
            const props = Object.values(propsObj).sort((a, b) => a.point - b.point);

            if (props.length === 0) {
              return null;
            }

            // Determine default prop (closest to EV)
            const defaultProp = props.reduce((prev, current) => {
              const prevDiff = Math.abs(convertOddsToEV(prev.odds));
              const currentDiff = Math.abs(convertOddsToEV(current.odds));
              return currentDiff < prevDiff ? current : prev;
            }, props[0]);

            // Calculate additional statistics
            const totalThreePM = (playerStat.last5_three_pm || []).reduce((a, b) => a + b, 0);
            const totalThreePA = (playerStat.last5_three_pa || []).reduce((a, b) => a + b, 0);
            const avgThreePM =
              playerStat.last5_three_pm && playerStat.last5_three_pm.length > 0
                ? (totalThreePM / playerStat.last5_three_pm.length).toFixed(2)
                : '0.00';
            const avgThreePA =
              playerStat.last5_three_pa && playerStat.last5_three_pa.length > 0
                ? (totalThreePA / playerStat.last5_three_pa.length).toFixed(2)
                : '0.00';
            const threePMPercentage =
              totalThreePA > 0 ? ((totalThreePM / totalThreePA) * 100).toFixed(2) : '0.00';

            // Extract last 5 game stats as objects
            // Handle cases where last5_game_details might be undefined or incomplete
            const last5Stats = (playerStat.last5_three_pm || []).map((pm, index) => {
              const pa = (playerStat.last5_three_pa && playerStat.last5_three_pa[index]) || 0;
              const gameDetails =
                (playerStat.last5_game_details && playerStat.last5_game_details[index]) || {};
              return {
                game: index + 1,
                threePM: pm,
                threePA: pa,
                date: gameDetails.date || 'N/A',
                opponent: gameDetails.opponent || 'N/A',
              };
            });

            return {
              originalName,
              normalizedName,
              props,
              selectedProp: defaultProp.point,
              profile_picture: profilePictureUrl,
              sportsbook_logo:
                sportsbookNameToLogo[defaultProp.sportsbook] || 'https://via.placeholder.com/24',
              bestOdds: defaultProp.odds,
              propLine: defaultProp.point,
              last5Stats,
              avg_threepm: avgThreePM,
              avg_threepa: avgThreePA,
              threepm_percentage: threePMPercentage,
            };
          })
          .filter((player) => player !== null);

        // Sort players by avg_threepm descending
        playerData.sort((a, b) => b.avg_threepm - a.avg_threepm);

        setPlayers(playerData);
      } catch (error) {
        console.error('Error fetching player odds:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayerOdds();
  }, [teamAbbreviation]);

  // Handler for changing prop number
  const handleChangeProp = (playerName, direction) => {
    setPlayers((prevPlayers) =>
      prevPlayers.map((player) => {
        if (player.originalName === playerName) {
          const currentIndex = player.props.findIndex(
            (prop) => Number(prop.point) === Number(player.selectedProp)
          );
          let newIndex = currentIndex;
          if (direction === 'increase' && currentIndex < player.props.length - 1) {
            newIndex = currentIndex + 1;
          } else if (direction === 'decrease' && currentIndex > 0) {
            newIndex = currentIndex - 1;
          }
          const newProp = player.props[newIndex];
          return {
            ...player,
            selectedProp: newProp.point,
            sportsbook_logo:
              sportsbookNameToLogo[newProp.sportsbook] || 'https://via.placeholder.com/24',
            bestOdds: newProp.odds,
            propLine: newProp.point,
          };
        }
        return player;
      })
    );
  };

  if (loading) {
    return <div className="tp-hub-loading">Loading...</div>;
  }

  if (players.length === 0) {
    return <div className="tp-hub-no-players">No player odds available.</div>;
  }

  const formatOdds = (odds) => {
    const numericOdds = parseInt(odds, 10);
    if (isNaN(numericOdds)) {
      return odds; // Return as is if not a number
    }
    return numericOdds > 0 ? `+${numericOdds}` : `${numericOdds}`;
  };

  // Function to label games with "G1," "G2," etc.
  const getGameLabel = (index) => `G${index + 1}`;

  return (
    <div className="tp-hub-player-list">
      {players.map((player) => {
        const currentIndex = player.props.findIndex(
          (prop) => prop.point === player.selectedProp
        );
        const isMin = currentIndex === 0;
        const isMax = currentIndex === player.props.length - 1;
        const displayProp = `O${player.selectedProp}`;
        return (
          <div key={player.originalName} className="tp-hub-player-container">
            <div className="tp-hub-player-header">
              <h4 className="tp-hub-player-name">{player.originalName}</h4>
              <span className="tp-hub-player-prop">O{player.selectedProp}</span>
            </div>
            <div className="tp-hub-player-body">
              <img
                src={player.profile_picture}
                alt={player.originalName}
                className="tp-hub-player-profile-picture"
                loading="lazy"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = 'https://via.placeholder.com/40';
                }}
              />
              <div className="tp-hub-sportsbook-section">
                <img
                  src={player.sportsbook_logo}
                  alt={player.bestOdds}
                  className="tp-hub-sportsbook-logo"
                  loading="lazy"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = 'https://via.placeholder.com/24';
                  }}
                />
                <span className="tp-hub-player-odds">{formatOdds(player.bestOdds)}</span>
              </div>
              <div className="tp-hub-player-stats">
                <table className="tp-hub-stats-table">
                  <thead>
                    <tr>
                      {/* Header Row for Last 5 Games */}
                      <th colSpan={player.last5Stats.length} className="tp-hub-game-header">
                        Last 5 Games
                      </th>
                    </tr>
                    <tr>
                      {/* Header Row for Combined 3PM-3PA */}
                      {player.last5Stats.map((_, index) => (
                        <th
                          key={index}
                          data-game-index={index}
                          className="tp-hub-stats-header"
                          title={`Date: ${player.last5Stats[index].date}\nOpponent: ${player.last5Stats[index].opponent}`}
                        >
                          {getGameLabel(index)}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="tp-hub-stats-data-row">
                      {/* Data Row: Combined 3PM-3PA */}
                      {player.last5Stats.map((stat, index) => (
                        <td
                          key={index}
                          data-game-index={index}
                          className="tp-hub-stats-data"
                          title={`Date: ${stat.date}\nOpponent: ${stat.opponent}`}
                        >
                          {formatGameStats(stat.threePM, stat.threePA)}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
                <div className="tp-hub-additional-stats highlighted-averages">
                  <span>Avg 3PM (Last 5 games): {player.avg_threepm}</span>
                  <span>Avg 3PA (Last 5 games): {player.avg_threepa}</span>
                  <span>3P% (Last 5 games): {player.threepm_percentage}%</span>
                </div>
              </div>
            </div>
            <div className="tp-hub-prop-buttons">
              <button
                className={`tp-hub-prop-button ${
                  isMin ? 'tp-hub-disabled' : 'tp-hub-decrease-button'
                }`}
                onClick={() => handleChangeProp(player.originalName, 'decrease')}
                disabled={isMin}
              >
                -
              </button>
              <button
                className={`tp-hub-prop-button ${
                  isMax ? 'tp-hub-disabled' : 'tp-hub-increase-button'
                }`}
                onClick={() => handleChangeProp(player.originalName, 'increase')}
                disabled={isMax}
              >
                +
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PlayerList;
